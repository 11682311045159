import React, { useState } from 'react';
import '../Styles/Coming.css';

function ComingSoon() {
  const [modalVisible, setModalVisible] = useState(false);

  const handleLogoClick = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <div className="container">
      <img
        src={require('../assets/logo.png')}
        alt="Logo"
        className="logo"
        onClick={handleLogoClick}
      />
      <div className="animation-container">
        <div className="cloud">
          <img
            src={require('../assets/flying.png')}
            alt="Cloud"
            className="cloud-image"
          />
        </div>
      </div>
      <h1>Coming Soon</h1>
    <div className="paragraph">
    <p>Software Development,Cloud Services,Network Solutions,Data Management,Software as a Services(SaaS),Business Continuity Planning,Cybersecurity Solutions,IT Security
</p>
    </div>

      {modalVisible && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content">
            <p><i title='Thanks' style={{color:"#5777fb"}} class="fa-solid fa-circle-info"></i> Thanks for visiting our site! </p>
            <p>Gratitude for exploring our site your presence is truly appreciated!</p>
            <button onClick={closeModal} class="custom-btn btn-7"><span>Close</span></button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ComingSoon;
