import React from 'react'
import ComingSoon from './Screens/ComingSoon'

const App = () => {
  return (
    <>
    
    
    <ComingSoon/>
    
    
    
    </>
  )
}

export default App